<template>
  <div id="app">
    <el-row type="flex" justify="center" align="middle">
      <el-col :xs="22" :sm="22" :md="20">
        <div class="header">
          <div class="header__logo">
            <img src="./assets/logo-large.png" />
          </div>
          <div class="header__title">
            Rent Calculator | 30% Social Income & 30% Assessable Income |
            updated 20/09/2023
          </div>
        </div>
      </el-col>
    </el-row>

    <form-component></form-component>
  </div>
</template>

<script>
import FormComponent from "./components/FormComponent";

export default {
  name: "app",
  components: {
    FormComponent
  }
};
</script>

<style lang="scss">
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.header {
  background: #ffffff;
  height: 84px;
  display: flex;
  justify-content: space-between;
}

.header__title {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 18px;
  font-weight: 600;
  color: #717578;
  padding-bottom: 16px;
}

.header__logo {
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;

  img {
    height: 48px;
  }
}

.background {
  background: url("./assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 24px;
}

.card {
  background: #ffffff;
  margin-bottom: 48px;
  padding: 60px 72px;

  h3 {
    color: #f1ae27;
    font-size: 16px;
    font-weight: 600;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  p {
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 400;
    margin-top: -24px;
    margin-bottom: 36px;
  }

  h3:first-child {
    margin-top: 0px;
  }
}

.card__bottom {
  margin-top: 48px;
}

.container--margin-bottom {
  margin-bottom: 48px;
}

.button--large {
  height: 48px;
  margin-top: 24px !important;
  width: 100%;
}

.button--small {
  height: 30px;
  margin-top: 12px !important;
  width: 100%;
}

.button--margin-top-fix {
  margin-top: 42px !important;
}

.column--margin-left-fix {
  margin-left: 1% !important;
}

.hidden-element {
  display: none;
  position: absolute;
  z-index: -1;
  width: 860px;
}

.el-date-editor {
  width: 100% !important;
}

.el-select {
  width: 100%;
}

.el-select-dropdown__list {
  max-width: 708px !important;
}

.el-button,
.el-input__inner,
.el-textarea__inner,
.el-select-dropdown,
.el-picker-panel,
.el-input-group__append,
.el-input-group__prepend {
  border-radius: 1px !important;
}
</style>
