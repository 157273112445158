<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Primary Tenant's Name"
          prop="primaryTenantName"
          :rules="rules.primaryTenantName"
        >
          <el-input v-model="fields.primaryTenantName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :offset="2" :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Street"
          prop="householdAddress.street"
          :rules="rules.street"
        >
          <el-input v-model="fields.householdAddress.street"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Suburb"
          prop="householdAddress.suburb"
          :rules="rules.suburb"
        >
          <el-input v-model="fields.householdAddress.suburb"></el-input>
        </el-form-item>
      </el-col>
      <el-col :offset="2" :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Postcode"
          prop="householdAddress.postcode"
          :rules="rules.postcode"
        >
          <el-input v-model="fields.householdAddress.postcode"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["fields", "rules"]
};
</script>
