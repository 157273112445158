import { incomeTypeWeight } from "./income-type-weights";
import { incomeTypes } from "./income-types";
import { craRates } from "./cra-rates";

const maxProportionOfMarketRentChargable = 0.7499;

function householdAssessableIncome(rentType, householdMembers) {
  let totalGrossHouseholdIncome = 0;
  let totalAssessableHouseholdIncome = 0;
  let incomeCalculatedRent = 0;

  householdMembers.forEach(member => {
    member.incomeItem.forEach(item => {
      const incomeAmount =
        item.incomeFrequency === "fortnightly" ? item.amount : item.amount * 2;
      const incomeClass =
        incomeTypes[Number(item["incomeType"])]["incomeClass"];
      const incomeWeight = incomeTypeWeight[incomeClass][rentType];

      totalGrossHouseholdIncome += incomeAmount;
      incomeCalculatedRent += incomeAmount * incomeWeight;

      if (incomeClass === "assessable" || incomeClass === "family") {
        totalAssessableHouseholdIncome += incomeAmount;
      }
    });
  });

  return {
    totalGrossHouseholdIncome,
    totalAssessableHouseholdIncome,
    incomeCalculatedRent
  };
}

export function getCalculations({
  rentAssistanceCategory,
  rentType,
  marketRent,
  propertyChargeAmount,
  members
}) {
  const fortnightlyPropertyChargeAmount = propertyChargeAmount * 2;
  const fortnightlyMarketRent = marketRent * 2;

  // -- totalGrossHouseholdIncome, totalAssessableHouseholdIncome, incomeCalculatedRent -- start --

  let {
    totalGrossHouseholdIncome,
    totalAssessableHouseholdIncome,
    incomeCalculatedRent
  } = householdAssessableIncome(rentType, members);

  // -- totalGrossHouseholdIncome, totalAssessableHouseholdIncome, incomeCalculatedRent -- end --

  // -- maximumChargableRent -- start --

  const maximumChargableRent =
    fortnightlyMarketRent * maxProportionOfMarketRentChargable;

  // -- maximumChargableRent -- end --

  // -- maxCRA -- start --

  const maximumFortnightlyCRA =
    craRates[rentAssistanceCategory]["maximumFortnightlyCRA"];

  const rentThreshold = craRates[rentAssistanceCategory]["rentThreshold"];

  const rawCalculatedCRA =
    3 *
    (incomeCalculatedRent + fortnightlyPropertyChargeAmount - rentThreshold);

  const maxCRA = Math.max(0, Math.min(rawCalculatedCRA, maximumFortnightlyCRA));

  // -- maxCRA -- end --

  // -- rentalSubsidy -- start --

  let rentalSubsidy1 = fortnightlyMarketRent - (incomeCalculatedRent + maxCRA);

  let rentalSubsidy2 = fortnightlyMarketRent - maximumChargableRent;

  let rentalSubsidy = Math.max(rentalSubsidy1, rentalSubsidy2);

  // -- rentalSubsidy -- end --

  // -- payableAmount -- start --

  const rentAssistance = Math.max(0, Math.min(maxCRA, maximumFortnightlyCRA));

  let payableAmount =
    incomeCalculatedRent + rentAssistance + fortnightlyPropertyChargeAmount;

  payableAmount =
    payableAmount > maximumChargableRent + fortnightlyPropertyChargeAmount
      ? maximumChargableRent + fortnightlyPropertyChargeAmount
      : payableAmount;

  // -- payableAmount -- end --

  return Object.assign(
    {},
    ...Object.entries({
      maximumChargableRent,
      totalGrossHouseholdIncome,
      totalAssessableHouseholdIncome,
      incomeCalculatedRent,
      maxCRA,
      rentalSubsidy,
      payableAmount
    }).map(([key, value]) => ({ [key]: Number(value / 2) }))
  );
}

export const calculationNames = {
  maximumChargableRent: "Maximum Chargable Rent",
  totalGrossHouseholdIncome: "Total Gross Household Income",
  totalAssessableHouseholdIncome: "Total Assessable Household Income",
  incomeCalculatedRent: "Income Calculated Rent",
  maxCRA: "Maximised Rent Assistance",
  rentalSubsidy: "Rental Subsidy",
  payableAmount: "Weekly Payable Amount"
};
