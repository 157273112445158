export const defaultTemplateData = () => ({
  name: "",
  street: "",
  suburb: "",
  postcode: "",
  weeklyMarketRent: "",
  maximumChargableRent: "",
  rentalSubsidy: "",
  additionalPropertyCharge: "",
  weeklyAmountPayable: "",
  rentEffectiveDate: "",
  rentalAssistanceCategory: "",
  calculationDate: "",
  householdAssessableIncome: "",
  incomeCalculatedRent: "",
  maximisedRentAssistance: "",
  householdMemberAssessableIncome: [],
  incomeCalculatedRentPercentage: ""
});

export const defaultHouseholdMember = () => ({
  key: Date.now(),
  name: "",
  dateOfBirth: "",
  incomeItem: []
});

export const defaultHouseholdMembers = () => [{ ...defaultHouseholdMember() }];

export const defaultFields = () => ({
  rentAssistanceCategory: "",
  rentType: "social",
  primaryTenantName: "",
  householdAddress: {
    street: "",
    suburb: "",
    postcode: ""
  },
  currentRent: "",
  marketRent: "",
  rentEffectiveDate: "",
  propertyChargeAmount: "",
  propertyChargeTypes: [],
  propertyChargeDescription: "",
  members: defaultHouseholdMembers()
});

export const defaultCalculationResults = () => [
  {
    type: "Maximum Chargable Rent",
    value: 0
  },
  {
    type: "Total Gross Household Income",
    value: 0
  },
  {
    type: "Total Assessable Household Income",
    value: 0
  },
  {
    type: "Income Calculated Rent",
    value: 0
  },
  {
    type: "Maximum Rent Assistance",
    value: 0
  },
  {
    type: "Rental Subsidy",
    value: 0
  },
  {
    type: "Weekly Payable Amount",
    value: 0
  }
];
