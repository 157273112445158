// TODO: Shorten incomeTypeName so it fits in selct box

export const incomeTypes = {
  1: { incomeTypeName: "Age Pension", incomeClass: "assessable" },
  2: { incomeTypeName: "Asylum Seekers Allowance", incomeClass: "assessable" },
  3: { incomeTypeName: "Austudy, Abstudy", incomeClass: "assessable" },
  4: {
    incomeTypeName: "Australian Service Pension",
    incomeClass: "assessable"
  },
  5: { incomeTypeName: "Blind Pension", incomeClass: "assessable" },
  6: {
    incomeTypeName: "Carer’s Payment",
    incomeClass: "assessable"
  },
  7: {
    incomeTypeName: "Centrelink Working Credits Scheme",
    incomeClass: "assessable"
  },
  8: {
    incomeTypeName: "Children’s Trust Funds (household member is a signatory)",
    incomeClass: "assessable"
  },
  9: {
    incomeTypeName: "Community Development Employment Project (CDEP)",
    incomeClass: "assessable"
  },
  10: {
    incomeTypeName: "Compensation payments",
    incomeClass: "assessable"
  },
  11: {
    incomeTypeName:
      "Deductions made by Centrelink to pensions or benefits due to overpayments",
    incomeClass: "assessable"
  },
  12: {
    incomeTypeName: "Defence Force Income Supplement (DFIS)",
    incomeClass: "assessable"
  },
  13: {
    incomeTypeName: "Defence Force Reserve Payments",
    incomeClass: "assessable"
  },
  14: {
    incomeTypeName: "Disability Support Pension",
    incomeClass: "assessable"
  },
  15: {
    incomeTypeName: "Disability Wage Supplement",
    incomeClass: "assessable"
  },
  16: {
    incomeTypeName: "Domestic Allowance (granted with War Widows Pension)",
    incomeClass: "assessable"
  },
  17: { incomeTypeName: "Energy Supplement", incomeClass: "assessable" },
  18: {
    incomeTypeName: "Energy Supplement Part A",
    incomeClass: "assessable"
  },
  19: {
    incomeTypeName: "Energy Supplement Part B",
    incomeClass: "assessable"
  },
  20: {
    incomeTypeName:
      "Family Tax Benefit Lump Sum Supplements from 23 October 2005",
    incomeClass: "assessable"
  },
  21: {
    incomeTypeName: "Family Tax Benefit Part A ",
    incomeClass: "family"
  },
  22: {
    incomeTypeName: "Family Tax Benefit Part B",
    incomeClass: "family"
  },
  23: { incomeTypeName: "Fringe Benefits", incomeClass: "assessable" },
  24: {
    incomeTypeName:
      "Gifts of regular income to assist with general living expenses",
    incomeClass: "assessable"
  },
  25: {
    incomeTypeName:
      "Gross wages, (including overtime, shift allowances, bonuses, risk allowances, etc.)",
    incomeClass: "assessable"
  },
  26: { incomeTypeName: "Incentive Allowance", incomeClass: "assessable" },
  27: {
    incomeTypeName:
      "Income generated from funds or assets (includes real estate)",
    incomeClass: "assessable"
  },
  28: {
    incomeTypeName: "Income Support Supplement (paid by Veterans’ Affairs)",
    incomeClass: "assessable"
  },
  29: {
    incomeTypeName:
      "Income generated from applying Centrelink’s deemed interest on investments and savings",
    incomeClass: "assessable"
  },
  30: {
    incomeTypeName:
      "Income generated from Income Stream Products (Annuities, Allocated Annuity Pensions, etc.)",
    incomeClass: "assessable"
  },
  31: {
    incomeTypeName: "Income received by Ministers of Religion",
    incomeClass: "assessable"
  },
  32: { incomeTypeName: "JobSeeker Payment", incomeClass: "assessable" },
  33: {
    incomeTypeName: "Lump sum compensation payments",
    incomeClass: "assessable"
  },
  34: {
    incomeTypeName: "Lump sum compensation payments",
    incomeClass: "assessable"
  },
  35: {
    incomeTypeName: "Maintenance Payments (includes payments ‘in-kind’)",
    incomeClass: "assessable"
  },
  36: { incomeTypeName: "Mature Age Allowance", incomeClass: "assessable" },
  37: {
    incomeTypeName: "Net profits (for self-employed)",
    incomeClass: "assessable"
  },
  38: {
    incomeTypeName: "New Enterprise Incentive Scheme (NEIS)",
    incomeClass: "assessable"
  },
  39: {
    incomeTypeName:
      "Overseas Income including Overseas War Service or Veterans Affairs Pensions",
    incomeClass: "assessable"
  },
  40: { incomeTypeName: "Paid Parental Leave", incomeClass: "assessable" },
  41: {
    incomeTypeName: "Parenting Payment Partnered",
    incomeClass: "assessable"
  },
  42: {
    incomeTypeName:
      "Parenting Payment Single (formerly known as Sole Parent Pension)",
    incomeClass: "assessable"
  },
  43: { incomeTypeName: "Partner Allowance", incomeClass: "assessable" },
  44: {
    incomeTypeName: "Payments ‘in kind’",
    incomeClass: "assessable"
  },
  45: { incomeTypeName: "Rent Assistance ", incomeClass: "assessable" },
  46: {
    incomeTypeName: "‘Salary Sacrificed’ income",
    incomeClass: "assessable"
  },
  47: {
    incomeTypeName: "Scholarship",
    incomeClass: "assessable"
  },
  48: {
    incomeTypeName: "Self-employed income – income derived from a business",
    incomeClass: "assessable"
  },
  49: {
    incomeTypeName: "Sheltered Workshop Payments",
    incomeClass: "assessable"
  },
  50: { incomeTypeName: "Sickness Allowance", incomeClass: "assessable" },
  51: { incomeTypeName: "Special Benefits", incomeClass: "assessable" },
  52: { incomeTypeName: "Superannuation payments", incomeClass: "assessable" },
  53: {
    incomeTypeName: "Sustentation Retirement Benefit",
    incomeClass: "assessable"
  },
  54: {
    incomeTypeName: "University Grants and Bursaries",
    incomeClass: "assessable"
  },
  55: {
    incomeTypeName: "Veterans’ Affairs Pensions",
    incomeClass: "assessable"
  },
  56: {
    incomeTypeName:
      "Veterans’ Affairs War Disability Pension (Defence force income supplement) ",
    incomeClass: "assessable"
  },
  57: { incomeTypeName: "War Widows Pension", incomeClass: "assessable" },
  58: { incomeTypeName: "Widows Allowance", incomeClass: "assessable" },
  59: { incomeTypeName: "Winnings", incomeClass: "assessable" },
  60: {
    incomeTypeName: "WorkCover and TAC payments – loss of income component",
    incomeClass: "assessable"
  },
  61: {
    incomeTypeName:
      "Work for the dole – only amounts less or more than the basic rate",
    incomeClass: "assessable"
  },
  62: { incomeTypeName: "Youth Allowance", incomeClass: "assessable" },
  63: {
    incomeTypeName:
      "Adequate Means of Support Pension paid by the Department of Veterans’ Affairs",
    incomeClass: "non_assessable"
  },
  64: {
    incomeTypeName:
      "Additional payments made by other countries to victims of war",
    incomeClass: "non_assessable"
  },
  65: { incomeTypeName: "Austudy Loan", incomeClass: "non_assessable" },
  66: {
    incomeTypeName: "Austudy Pensioner Educational Supplement",
    incomeClass: "non_assessable"
  },
  67: {
    incomeTypeName: "Baby Bonus (formerly known as Maternity Payment) ",
    incomeClass: "non_assessable"
  },
  68: { incomeTypeName: "Bereavement Payment", incomeClass: "non_assessable" },
  69: { incomeTypeName: "Book Allowance", incomeClass: "non_assessable" },
  70: {
    incomeTypeName:
      "Carer’s Allowance (formerly known as Child Disability Allowance and Domiciliary Allowance)",
    incomeClass: "non_assessable"
  },
  71: {
    incomeTypeName:
      "Community Development Employment Project (CDEP) Payment Supplement",
    incomeClass: "non_assessable"
  },
  72: {
    incomeTypeName:
      "Community Empowerment Project Payments (paid by the City of Yarra)",
    incomeClass: "non_assessable"
  },
  73: { incomeTypeName: "Child Care Benefit", incomeClass: "non_assessable" },
  74: {
    incomeTypeName:
      "Children’s Trust Funds where the household member has no access to the funds",
    incomeClass: "non_assessable"
  },
  75: {
    incomeTypeName: "Clean Energy Advance",
    incomeClass: "non_assessable"
  },
  76: {
    incomeTypeName: "Department of Human Services Caregiver payments",
    incomeClass: "non_assessable"
  },
  77: {
    incomeTypeName: "Double Orphan Allowance",
    incomeClass: "non_assessable"
  },
  78: {
    incomeTypeName:
      "Double Orphan Pension (assessable prior to 23 November 2008)",
    incomeClass: "non_assessable"
  },
  79: { incomeTypeName: "Education Allowances", incomeClass: "non_assessable" },
  80: {
    incomeTypeName: "Education Entry Payment",
    incomeClass: "non_assessable"
  },
  81: {
    incomeTypeName:
      "F-111 ex-gratia $40,000 or $10,000 lump sum payments for participants in the F-111 Deseal/Reseal programs",
    incomeClass: "non_assessable"
  },
  82: {
    incomeTypeName: "Family Tax Benefits for 6th and subsequent child",
    incomeClass: "non_assessable"
  },
  83: {
    incomeTypeName:
      "Family Tax Initiative (For assessments prior to 1 October 2000)",
    incomeClass: "non_assessable"
  },
  84: { incomeTypeName: "Fares Assistance", incomeClass: "non_assessable" },
  85: {
    incomeTypeName:
      "Formal Training Allowance - paid with Centrelink pension/allowance",
    incomeClass: "non_assessable"
  },
  86: { incomeTypeName: "Foster Allowance", incomeClass: "non_assessable" },
  87: { incomeTypeName: "GST for allowances", incomeClass: "non_assessable" },
  88: {
    incomeTypeName: "Income of residents under 18 years of age",
    incomeClass: "non_assessable"
  },
  89: {
    incomeTypeName: "Language, Literacy and Numeracy Supplement",
    incomeClass: "non_assessable"
  },
  90: {
    incomeTypeName: "Large Family Supplement",
    incomeClass: "non_assessable"
  },
  91: {
    incomeTypeName:
      "Lump sum funds invested into income stream products such as annuities",
    incomeClass: "non_assessable"
  },
  92: {
    incomeTypeName: "Mobility Allowance",
    incomeClass: "non_assessable"
  },
  93: {
    incomeTypeName: "Multiple Birth Allowance",
    incomeClass: "non_assessable"
  },
  94: {
    incomeTypeName: "Orphan Pension (18 years and under)",
    incomeClass: "non_assessable"
  },
  95: {
    incomeTypeName:
      "Pension Supplement (formerly known as GST Component of Pensions)",
    incomeClass: "non_assessable"
  },
  96: {
    incomeTypeName: "Pharmaceutical Allowance",
    incomeClass: "non_assessable"
  },
  97: {
    incomeTypeName: "Remedial Tuition Allowance",
    incomeClass: "non_assessable"
  },
  98: {
    incomeTypeName: "Restitution payments from Foreign Governments",
    incomeClass: "non_assessable"
  },
  99: { incomeTypeName: "Schoolkids bonus", incomeClass: "non_assessable" },
  100: {
    incomeTypeName: "School Start Bonus",
    incomeClass: "non_assessable"
  },
  101: {
    incomeTypeName:
      "Superannuation funds, lifetime annuities that cannot be realised or drawn from",
    incomeClass: "non_assessable"
  },
  102: {
    incomeTypeName:
      "Superannuation funds, lifetime annuities of persons under retiring age whose funds are realisable but choose not to access them.",
    incomeClass: "non_assessable"
  },
  103: {
    incomeTypeName: "TAC Impairment Annuity Benefit",
    incomeClass: "non_assessable"
  },
  104: {
    incomeTypeName: "Telephone Rental Concession",
    incomeClass: "non_assessable"
  },
  105: {
    incomeTypeName: "Travel, Meals, Laundry and Accommodation Allowances",
    incomeClass: "non_assessable"
  },
  106: {
    incomeTypeName: "War Disability Pensions (Department of Veterans’ Affairs)",
    incomeClass: "non_assessable"
  },
  107: {
    incomeTypeName: "Work for the Dole Supplement – only the base rate amount",
    incomeClass: "non_assessable"
  },
  108: {
    incomeTypeName: "Youth Disability Supplement",
    incomeClass: "non_assessable"
  }

  // added income types
};
