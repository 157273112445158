import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { format } from "date-fns";
import { incomeTypes } from "../resources/income-types";
import { getCalculations, calculationNames } from "../resources/calculations";
import { rentAssistanceCategoryName } from "../resources/rent-assistance-category";

export const roundDownFiveCent = value => {
  const [beforeDP, afterDP] = Number(value)
    .toFixed(3) // changed this
    .split(".");

  return Number(afterDP[1]) >= 5
    ? Number(`${beforeDP}.${afterDP[0]}5`)
    : Number(`${beforeDP}.${afterDP[0]}0`);
};

export const roundDownOneCent = value => {
  const [beforeDP, afterDP] = Number(value)
    .toFixed(3)
    .split(".");

  return Number(Number(`${beforeDP}.${afterDP.substring(0, 2)}`).toFixed(2));
};

export const getTemplateDataFromValues = (fields, calculations) => {
  let householdMemberAssessableIncome = [];

  fields.members.forEach(member => {
    member.incomeItem.forEach(item => {
      const isAccessableIncome =
        incomeTypes[Number(item.incomeType)].incomeClass == "assessable" ||
        incomeTypes[Number(item.incomeType)].incomeClass == "family";

      if (isAccessableIncome) {
        householdMemberAssessableIncome.push({
          name: member.name,
          incomeType: incomeTypes[item.incomeType].incomeTypeName,
          grossWeeklyIncome: `$${roundDownFiveCent(
            item.incomeFrequency === "weekly" ? item.amount : item.amount / 2
          ).toFixed(2)}`
        });
      }
    });
  });

  const additionalPropertyChargesStandard = fields.propertyChargeTypes
    .filter(item => item !== "other")
    .map(item => item.replace(/^\w/, item => item.toUpperCase()))
    .join(" - ");

  const additionalPropertyChargesOther =
    !!fields.propertyChargeDescription &&
    fields.propertyChargeTypes.includes("other") &&
    `Other: ${
      fields.propertyChargeDescription
        ? fields.propertyChargeDescription
        : "no description provided"
    }`;

  return {
    name: fields.primaryTenantName,
    street: fields.householdAddress.street,
    suburb: fields.householdAddress.suburb.toUpperCase(),
    postcode: String(fields.householdAddress.postcode),
    weeklyMarketRent: `$${roundDownFiveCent(fields.marketRent).toFixed(2)}`,
    maximumChargableRent: `$${roundDownFiveCent(
      calculations.maximumChargableRent
    ).toFixed(2)}`,
    rentalSubsidy: `$${roundDownFiveCent(calculations.rentalSubsidy).toFixed(
      2
    )}cr`,
    additionalPropertyCharge: `$${roundDownFiveCent(
      fields.propertyChargeAmount
    ).toFixed(2)}`,
    weeklyAmountPayable: `$${roundDownFiveCent(
      calculations.payableAmount
    ).toFixed(2)}`,
    rentEffectiveDate: format(
      new Date(fields.rentEffectiveDate),
      "dd MMMM yyyy"
    ),
    rentalAssistanceCategory:
      rentAssistanceCategoryName[fields.rentAssistanceCategory],
    calculationDate: format(new Date(), "dd MMMM yyyy"),
    householdAssessableIncome: `$${roundDownFiveCent(
      calculations.totalAssessableHouseholdIncome
    ).toFixed(2)}`,
    incomeCalculatedRent: `$${roundDownFiveCent(
      calculations.incomeCalculatedRent
    ).toFixed(2)}`,
    maximisedRentAssistance: `$${roundDownFiveCent(calculations.maxCRA).toFixed(
      2
    )}`,
    incomeCalculatedRentPercentage: fields.rentType === "social" ? "30" : "30",
    householdMemberAssessableIncome,
    additionalPropertyChargesStandard,
    additionalPropertyChargesOther
  };
};

export const getCalculationResults = calculations => {
  return Object.keys(calculations).map(key => ({
    type: calculationNames[key],
    value: `$${roundDownOneCent(calculations[key]).toFixed(2)}`
  }));
};

export const getCalculationResultsAndTemplateData = fields => {
  // convert strings to numbers for monetary fields
  fields.marketRent = Number(fields.marketRent);
  fields.propertyChargeAmount = Number(fields.propertyChargeAmount);
  fields.members = fields.members.map(item => {
    return {
      ...item,
      incomeItem: item.incomeItem.map(item => {
        return {
          ...item,
          amount: Number(item.amount)
        };
      })
    };
  });

  const calculations = getCalculations(fields);
  const templateData = getTemplateDataFromValues(fields, calculations);
  const calculationResults = getCalculationResults(calculations);

  return { templateData, calculationResults };
};

export const generatePdfReport = async (pdfReport, fileName) => {
  const canvas = await html2canvas(pdfReport, {
    scrollX: 0,
    scrollY: -window.scrollY
  });

  document.getElementById("capture").style.display = "none";

  const doc = new jsPDF("portrait", "mm", "a4");
  const imgData = canvas.toDataURL("image/png");

  const imgWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  let heightLeft = imgHeight;
  let position = 0;

  doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }

  doc.save(`${fileName}.pdf`);
};
