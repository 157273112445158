<template>
  <div class="container--margin-bottom">
    <el-row :gutter="20">
      <el-col :xs="11" :sm="12" :md="12">
        <el-form-item
          label="Household Member Name"
          :prop="'members.' + memberIndex + '.name'"
          :rules="rules.name"
        >
          <el-input v-model="data.name"></el-input>
        </el-form-item>
      </el-col>

      <el-col :offset="2" :xs="10" :sm="10" :md="10">
        <el-form-item
          label="Household Member Date of Birth"
          :prop="'members.' + memberIndex + '.dateOfBirth'"
          :rules="rules.dateOfBirth"
        >
          <el-date-picker
            v-model="data.dateOfBirth"
            type="date"
            placeholder="DD/MM/YYYY"
            format="dd/MM/yyyy"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24">
        <div v-for="(item, itemIndex) in data.incomeItem" :key="item.key">
          <el-row>
            <el-col :offset="2" :xs="6" :sm="6" :md="6">
              <el-form-item
                label="Income Type"
                :prop="
                  'members.' +
                    memberIndex +
                    '.incomeItem.' +
                    itemIndex +
                    '.incomeType'
                "
                :rules="rules.incomeType"
              >
                <el-select filterable v-model="item.incomeType" placeholder="">
                  <el-option
                    v-for="(value, key) in incomeTypes"
                    :label="value.incomeTypeName.substring(0, 200)"
                    :value="key"
                    v-bind:key="key"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :offset="1" :xs="6" :sm="6" :md="6">
              <el-form-item
                label="Income Amount"
                :prop="
                  'members.' +
                    memberIndex +
                    '.incomeItem.' +
                    itemIndex +
                    '.amount'
                "
                :rules="rules.amount"
              >
                <el-input v-model="item.amount">
                  <template slot="prepend">$</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :offset="1" :xs="5" :sm="5" :md="5">
              <el-form-item
                label="Income Frequency"
                :prop="
                  'members.' +
                    memberIndex +
                    '.incomeItem.' +
                    itemIndex +
                    '.incomeFrequency'
                "
                :rules="rules.incomeFrequency"
              >
                <el-select
                  filterable
                  v-model="item.incomeFrequency"
                  placeholder=""
                >
                  <el-option label="Weekly" value="weekly"></el-option>
                  <el-option
                    label="Fortnightly"
                    value="fortnightly"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :offset="1" :xs="1" :sm="1" :md="1">
              <el-form-item>
                <el-button
                  circle
                  type="danger"
                  icon="el-icon-delete"
                  class="button--margin-top-fix "
                  @click.prevent="removeItem(item)"
                ></el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :offset="2" :xs="19" :sm="19" :md="19">
        <el-form-item>
          <el-button @click="addItem" class="button--small" plain
            >Add Income</el-button
          >
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { incomeTypes } from "../resources/income-types";

export default {
  props: ["member", "rules", "memberIndex"],
  data() {
    return {
      data: this.member,
      incomeTypes
    };
  },

  methods: {
    removeItem(item) {
      const index = this.data.incomeItem.indexOf(item);

      if (index !== -1) {
        this.data.incomeItem.splice(index, 1);
      }
    },

    addItem() {
      this.data.incomeItem.push({
        key: Date.now(),
        amount: "",
        incomeType: "",
        incomeFrequency: ""
      });
    }
  }
};
</script>
