export const craRates = {
  1: {
    situation: "Not eligible",
    maximumFortnightlyCRA: 0,
    rentThreshold: 0,
    minRentForMaxCRA: 0
  },
  2: {
    situation: "Single, no children",
    maximumFortnightlyCRA: 184.8,
    rentThreshold: 143.4,
    minRentForMaxCRA: 389.8
  },
  3: {
    situation: "Single, no children, sharer",
    maximumFortnightlyCRA: 123.2,
    rentThreshold: 143.4,
    minRentForMaxCRA: 307.67
  },
  4: {
    situation: "Single, one or two children",
    maximumFortnightlyCRA: 217.28,
    rentThreshold: 188.44,
    minRentForMaxCRA: 478.15
  },
  5: {
    situation: "Single, three or more children",
    maximumFortnightlyCRA: 245.42,
    rentThreshold: 188.44,
    minRentForMaxCRA: 515.67
  },
  6: {
    situation: "Couple, no children",
    maximumFortnightlyCRA: 174.0,
    rentThreshold: 232.4,
    minRentForMaxCRA: 464.4
  },
  7: {
    situation: "Couple, one or two children",
    maximumFortnightlyCRA: 217.28,
    rentThreshold: 278.46,
    minRentForMaxCRA: 568.17
  },
  8: {
    situation: "Couple, three or more children",
    maximumFortnightlyCRA: 245.42,
    rentThreshold: 278.46,
    minRentForMaxCRA: 605.69
  },
  9: {
    situation: "Couple Separated, one or two children",
    maximumFortnightlyCRA: 217.28,
    rentThreshold: 188.44,
    minRentForMaxCRA: 478.15
  },
  10: {
    situation: "Couple Separated, three or more children",
    maximumFortnightlyCRA: 245.42,
    rentThreshold: 188.44,
    minRentForMaxCRA: 515.67
  },
  11: {
    situation: "Partnered, illness seperated, no children",
    maximumFortnightlyCRA: 184.8,
    rentThreshold: 143.4,
    minRentForMaxCRA: 389.8
  },
  12: {
    situation: "Partnered, temporarily seperated, no children",
    maximumFortnightlyCRA: 174.0,
    rentThreshold: 143.4,
    minRentForMaxCRA: 375.4
  }
};
