<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Rent Effective Date"
          prop="rentEffectiveDate"
          :rules="rules.rentEffectiveDate"
        >
          <el-date-picker
            v-model="fields.rentEffectiveDate"
            type="date"
            placeholder="DD/MM/YYYY"
            format="dd/MM/yyyy"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :offset="2" :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Market Rent"
          prop="marketRent"
          :rules="rules.marketRent"
        >
          <el-input v-model="fields.marketRent">
            <template slot="prepend">$</template>
            <template slot="append">per week</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Property Charges"
          prop="propertyChargeAmount"
          :rules="rules.propertyChargeAmount"
        >
          <el-input v-model="fields.propertyChargeAmount">
            <template slot="prepend">$</template>
            <template slot="append">per week</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :offset="2" :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Property Charge Options"
          prop="propertyChargeTypes"
          :rules="rules.propertyChargeTypes"
        >
          <el-select
            filterable
            multiple
            v-model="fields.propertyChargeTypes"
            placeholder=""
          >
            <el-option label="Electricity" value="electricity"></el-option>
            <el-option label="Gas" value="gas"></el-option>
            <el-option label="Water" value="water"></el-option>
            <el-option label="Laundry" value="laundry"></el-option>
            <el-option label="Other" value="other"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24">
        <el-form-item
          label="Other Property Charge Description"
          prop="propertyChargeDescription"
          :rules="rules.propertyChargeDescription"
        >
          <el-input
            v-model="fields.propertyChargeDescription"
            type="textarea"
            :rows="2"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["fields", "rules"]
};
</script>
