export const incomeTypeWeight = {
  assessable: {
    social: 0.3,
    affordable: 0.3
  },
  non_assessable: {
    social: 0,
    affordable: 0
  },
  family: {
    social: 0.15,
    affordable: 0.3
  }
};
