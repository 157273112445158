<template>
  <div class="report-box">
    <table cellpadding="0" cellspacing="0">
      <tr class="top">
        <td colspan="2">
          <table>
            <tr>
              <td class="title">
                <img
                  src="../assets/logo-large.png"
                  style="width:100%; max-width:260px;"
                />
              </td>

              <td>
                <div class="report-title">
                  <br />Tenant Weekly Payable <br />Calculation Confirmation
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="information">
        <td colspan="2">
          <table>
            <tr>
              <td>
                {{ data.name }}
                <br />
                {{ data.street }}
                <br />
                {{ data.suburb }} VIC {{ data.postcode }}
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <table class="report-table condenced">
        <tr class="details">
          <td>Weekly Market Rent</td>

          <td>{{ data.weeklyMarketRent }}</td>
        </tr>

        <tr class="details">
          <td>Maximum chargeable Rent</td>

          <td>{{ data.maximumChargableRent }}</td>
        </tr>

        <tr class="details">
          <td>Calculation date</td>

          <td>{{ data.calculationDate }}</td>
        </tr>

        <tr class="details">
          <td>Household assessable income</td>

          <td>
            {{
              data.householdMemberAssessableIncome &&
                `$${data.householdMemberAssessableIncome
                  .reduce((sum, item) => {
                    return (
                      sum + Number(item.grossWeeklyIncome.replace("$", ""))
                    );
                  }, 0)
                  .toFixed(2)}`
            }}
          </td>
        </tr>

        <tr class="details">
          <td>
            Income calculated rent (@{{ data.incomeCalculatedRentPercentage }}%
            of assessable income)
          </td>

          <td>{{ data.incomeCalculatedRent }}</td>
        </tr>

        <tr class="details">
          <td>Maximised Rent Assistance on the income based rent</td>

          <td>{{ data.maximisedRentAssistance }}</td>
        </tr>

        <tr class="details">
          <td>Rental Subsidy</td>

          <td>{{ data.rentalSubsidy }}</td>
        </tr>

        <tr class="details">
          <td>Additional Property charge</td>

          <td>{{ data.additionalPropertyCharge }}</td>
        </tr>

        <tr class="details bold-text">
          <td>Total Weekly Amount Payable</td>

          <td>{{ data.weeklyAmountPayable }}</td>
        </tr>

        <tr class="details bold-text">
          <td>Rent effective date</td>

          <td>{{ data.rentEffectiveDate }}</td>
        </tr>
      </table>

      <!-- Household’s assessable income used in rent calculation: -->
      <div class="report-assessable-income-box">
        <h5>Household’s assessable income used in rent calculation</h5>
        <table class="report-table">
          <tr class="heading">
            <td>Name</td>

            <td>Income Type</td>

            <td>Gross Weekly Income</td>
          </tr>

          <tr
            class="item"
            v-for="(member, index) in data.householdMemberAssessableIncome"
            :key="index"
          >
            <td>
              {{
                index > 0 &&
                member.name ===
                  data.householdMemberAssessableIncome[index - 1].name
                  ? ""
                  : member.name
              }}
            </td>

            <td>{{ member.incomeType }}</td>

            <td>{{ member.grossWeeklyIncome }}</td>
          </tr>

          <tr class="total">
            <td></td>
            <td>Total assessable income:</td>
            <td>
              {{
                data.householdMemberAssessableIncome &&
                  `$${data.householdMemberAssessableIncome
                    .reduce((sum, item) => {
                      return (
                        sum + Number(item.grossWeeklyIncome.replace("$", ""))
                      );
                    }, 0)
                    .toFixed(2)}`
              }}
            </td>
          </tr>
        </table>
      </div>

      <div class="report-assessable-income-box">
        <h5>Definitions</h5>
        <ul>
          <li v-if="!!data.additionalPropertyChargesStandard">
            Additional Property Charge
            <ul>
              <li>{{ data.additionalPropertyChargesStandard }}</li>
            </ul>
          </li>

          <li v-if="data.additionalPropertyChargesOther">
            Additional Property Charge - Other
            <ul>
              <li>{{ data.additionalPropertyChargesOther }}</li>
            </ul>
          </li>

          <li>
            Rental Subsidy = Market Rent – [(Income Calculated Rent + Rent
            Assistance) or Maximum chargeable rent, whichever is lower $ value]
          </li>
        </ul>
      </div>
      <div class="Bond-info">
        Bond is equivalent of 2 weeks rent (do not include additional property
        charge) for rooming house OR 4 weeks rent (do not include additional
        property charge) for RTA
      </div>
      <div class="report-assessable-income-footer">
        In properties where there are additional property charges, monies paid
        will be allocated as determined by Unison.
      </div>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data"]
};
</script>

<style scoped>
.report-box {
  background: #ffffff;
  max-width: 800px;
  /* margin: auto; */
  padding: 30px;
  /* border: 1px solid #eee; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.report-table {
  font-size: 12px;
}

.condenced tbody tr td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.condenced tbody tr td:nth-child(2) {
  text-align: left !important;
}

.condenced {
  margin-bottom: 36px !important;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 2px 0px;
}

.bold-text {
  font-weight: 600;
}

.report-title {
  font-weight: 600;
}

.report-assessable-income-box table tr td:nth-child(2) {
  text-align: left !important;
}

.report-assessable-income-box ul {
  font-size: 12px;
}

.report-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.report-box table td {
  padding: 2px 5px;
  vertical-align: top;
}

.report-box table tr td:nth-child(2) {
  text-align: right;
}

.report-box table tr.top table td {
  padding-bottom: 48px;
}

.report-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.report-box table tr.information table td {
  padding-top: 12px;
  padding-bottom: 56px;
}

.report-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.report-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.report-box table tr.item.last td {
  border-bottom: none;
}

.report-box table tr.total td {
  padding-top: 2px;
  border-top: 2px solid #eee;
  font-weight: bold;
}
.Bond-info {
  font-size: 12px;
  font-style: italic;
  padding-top: 48px;
  text-align: center;
}
.report-assessable-income-footer {
  font-size: 12px;
  font-style: italic;
  padding-top: 48px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .report-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .report-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

.report-table-condenced tr td {
  padding: 0px;
}
</style>
