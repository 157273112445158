<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="11" :sm="11" :md="11">
        <el-form-item
          label="Rent Assistance Category"
          prop="rentAssistanceCategory"
          :rules="rules.rentAssistanceCategory"
        >
          <el-select
            filterable
            v-model="fields.rentAssistanceCategory"
            placeholder=""
          >
            <el-option
              v-for="(categoryName,
              categoryKey) in selectOptions.rentAssistanceCategoryName"
              :label="categoryName"
              :value="categoryKey"
              v-bind:key="categoryKey"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :offset="2" :xs="11" :sm="11" :md="11">
        <el-form-item label="Rent Type" prop="rentType" :rules="rules.rentType">
          <el-select filterable v-model="fields.rentType" placeholder="">
            <el-option label="Social" value="social"></el-option>
            <el-option label="Affordable" value="affordable"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { rentAssistanceCategoryName } from "../resources/rent-assistance-category";

export default {
  props: ["fields", "rules"],
  data() {
    return {
      selectOptions: {
        rentAssistanceCategoryName
      }
    };
  }
};
</script>
