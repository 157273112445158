const isNumberOrEmptyString = (rule, value, callback) => {
  if (value == 0 || !!Number(value) || 0 === String(value).length) {
    callback();
  } else {
    callback(new Error("Current rent must be a number"));
  }
};

export const rules = {
  name: [
    {
      required: true,
      message: "Name is requred",
      trigger: "blur"
    }
  ],
  amount: [
    {
      required: true,
      message: "Amount is requred",
      trigger: "blur"
    },
    {
      pattern: /^[0-9]+\.?[0-9]?[0-9]?$/,
      message: "Amount must be a number up to 2 decimal places",
      trigger: "blur"
    }
  ],
  incomeType: [
    {
      required: true,
      message: "Please select income type",
      trigger: "change"
    }
  ],
  incomeFrequency: [
    {
      required: true,
      message: "Please select income frequency",
      trigger: "change"
    }
  ],
  rentAssistanceCategory: [
    {
      required: true,
      message: "Please select rent assistance category",
      trigger: "change"
    }
  ],
  rentType: [
    {
      required: true,
      message: "Please select rent type",
      trigger: "change"
    }
  ],
  primaryTenantName: [
    {
      required: true,
      message: "Primary tenant's name is requred",
      trigger: "blur"
    }
  ],
  street: [
    {
      required: true,
      message: "Street address is requred",
      trigger: "blur"
    }
  ],
  suburb: [
    {
      required: true,
      message: "Suburb is required",
      trigger: "blur"
    }
  ],
  postcode: [
    {
      required: true,
      message: "Postcode is required",
      trigger: "blur"
    },
    {
      pattern: /^[0-9]{4}$/,
      message: "Postcode must be four digits",
      trigger: "blur"
    }
  ],
  currentRent: [
    {
      validator: isNumberOrEmptyString,
      trigger: "blur"
    }
  ],
  marketRent: [
    {
      required: true,
      message: "Market rent is required",
      trigger: "blur"
    },
    {
      pattern: /^[0-9]+\.?[0-9]?[0-9]?$/,
      message: "Market Rent must be a number up to 2 decimal places",
      trigger: "blur"
    }
  ],
  rentEffectiveDate: [
    {
      type: "date",
      required: true,
      message: "Rent effective date is required",
      trigger: "change"
    }
  ],
  propertyChargeAmount: [
    {
      pattern: /^[0-9]+\.?[0-9]?[0-9]?$/,
      message: "Property Charge must be a number up to 2 decimal places",
      trigger: "blur"
    }
  ],
  propertyChargeTypes: [],
  propertyChargeDescription: [],
  dateOfBirth: [
    {
      type: "date",
      required: true,
      message: "Date of birth is required",
      trigger: "change"
    }
  ]
};
