<template>
  <div>
    <div id="capture" class="hidden-element">
      <pdf-report-component :data="templateData"></pdf-report-component>
    </div>

    <div id="canvas"></div>

    <div class="background">
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :md="20">
          <div class="card">
            <el-form
              :model="fields"
              label-position="top"
              ref="calculator-form"
              size="small"
            >
              <h3>Family and Rent Type</h3>

              <family-rent-types-component
                :fields="fields"
                :rules="rules"
              ></family-rent-types-component>

              <h3>Household Details</h3>

              <household-details-component
                :fields="fields"
                :rules="rules"
              ></household-details-component>

              <h3>Household Expenses</h3>

              <household-costs-component
                :fields="fields"
                :rules="rules"
              ></household-costs-component>

              <h3>Household Income</h3>

              <div
                v-for="(item, index) in fields.members"
                v-bind:key="item.key"
              >
                <el-row :gutter="20">
                  <el-col :xs="22" :sm="22" :md="22">
                    <div>
                      <household-member-component
                        :member="item"
                        :rules="rules"
                        :memberIndex="index"
                      ></household-member-component>
                    </div>
                  </el-col>

                  <el-col
                    :xs="1"
                    :sm="1"
                    :md="1"
                    class="column--margin-left-fix"
                  >
                    <el-form-item>
                      <el-button
                        circle
                        type="danger"
                        icon="el-icon-delete"
                        class="button--margin-top-fix"
                        :disabled="fields.members.length == 1"
                        @click.prevent="removePerson(item)"
                      ></el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>

              <el-row :gutter="20">
                <el-col :xs="9" :sm="9" :md="9">
                  <el-form-item>
                    <el-button class="button--small" @click="addPerson"
                      >Add Household Member</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>

              <div class="card__bottom">
                <el-row :gutter="20">
                  <el-col :xs="9" :sm="9" :md="9">
                    <el-button
                      class="button--large"
                      type="primary"
                      @click="submitForm('calculator-form')"
                      >Calculate</el-button
                    >
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :xs="9" :sm="9" :md="9">
                    <el-button
                      class="button--large"
                      @click="resetForm('calculator-form')"
                      >Reset</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>

          <div ref="calculations-summary">
            <div class="card" v-if="displayCalculations">
              <h3>Summary</h3>
              <p>All values are weekly</p>

              <el-table :data="calculationResults" :show-header="false">
                <el-table-column prop="type"> </el-table-column>
                <el-table-column prop="value"> </el-table-column>
              </el-table>

              <el-row :gutter="20">
                <el-col :xs="9" :sm="9" :md="9">
                  <div class="card__bottom">
                    <el-button
                      class="button--large"
                      type="primary"
                      @click="generatePdf"
                      :loading="isGeneratingPdf"
                      >Generate PDF</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import HouseholdMemberComponent from "./HouseholdMemberComponent";
import FamilyRentTypesComponent from "./FamilyRentTypesComponent";
import HouseholdCostsComponent from "./HouseholdCostsComponent";
import HouseholdDetailsComponent from "./HouseholdDetailsComponent";
import PdfReportComponent from "./PdfReportComponent";
import { format } from "date-fns";
import { rules } from "./../resources/validation-rules";
import {
  getCalculationResultsAndTemplateData,
  generatePdfReport
} from "../resources/utilities";
import {
  defaultTemplateData,
  defaultHouseholdMember,
  defaultHouseholdMembers,
  defaultFields,
  defaultCalculationResults
} from "../resources/default-form-data";

export default {
  components: {
    HouseholdMemberComponent,
    FamilyRentTypesComponent,
    HouseholdCostsComponent,
    HouseholdDetailsComponent,
    PdfReportComponent
  },

  data() {
    return {
      calculationResults: defaultCalculationResults(),
      templateData: defaultTemplateData(),
      fields: defaultFields(),
      displayCalculations: false,
      isGeneratingPdf: false,
      rules
    };
  },

  methods: {
    addPerson() {
      this.fields.members.push(defaultHouseholdMember());
    },

    removePerson(item) {
      const index = this.fields.members.indexOf(item);

      if (index !== -1) {
        this.fields.members.splice(index, 1);
      }
    },

    async generatePdf() {
      const { primaryTenantName } = this.fields;
      const name = `${primaryTenantName.toLowerCase().replace(" ", "-")}-`;
      const date = format(new Date(), "yyyy-MM-dd");
      const fileName = `${name}${date}`;
      this.isGeneratingPdf = true;

      const pdfReport = document.getElementById("capture");
      pdfReport.style.display = "block";
      await generatePdfReport(pdfReport, fileName);
      this.isGeneratingPdf = false;
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const {
            templateData,
            calculationResults
          } = getCalculationResultsAndTemplateData({ ...this.fields });

          this.calculationResults = calculationResults;
          this.templateData = templateData;
          this.displayCalculations = true;
          window.scrollTo(0, this.$refs["calculations-summary"].offsetTop);
          return;
        } else {
          return false;
        }
      });
    },

    resetForm(formName) {
      this.displayCalculations = false;
      this.$refs[formName].resetFields();
      this.templateData = defaultTemplateData();
      this.fields.members = defaultHouseholdMembers();
    }
  }
};
</script>
